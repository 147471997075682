.timer {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fheader%2Fbtn-timing.svg?alt=media&token=3615f1a8-6c27-4ea1-9fc7-883cbce37022);
    height: 62px;
    width: 138px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}

.timer p {
    margin-left: 45px;
    margin-top: 30px;
}