.tablette_button {
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.tablette_open {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftablette%2Ftab-on.svg?alt=media&token=38bdd977-bec0-4002-8227-3e711e6f95e2);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 16/10;
    height: 46vh;
    width: auto;
    padding: 7vh;
    margin-left: 9vh;
}

.tablette_open p {
    margin-right: 50vh;
    margin-top: 10vh;
}
