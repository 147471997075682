.steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;
}

.steps-title {
    color: white;
    display: flex;
    flex-direction: column;
    margin: 5vh;
}

.steps-title h1, .steps-title p {
    margin: 0;
}