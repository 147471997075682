.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 20px;
}

.header-right {
    display: flex;
    align-items: center;
}

.header-right-button {
    text-decoration: none;
    border: none;
    height: 50px;
    width: 60px;
    background-color: transparent;
    margin: 0 5px;
}

.header-right-button--music {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-music.svg?alt=media&token=a8c6b586-280d-4a4f-bb45-84e75a8525f4);
}

.header-right-button--no-music {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-nomusic.svg?alt=media&token=89085a79-c1e3-4c6a-b4cc-96c029176f28);
}

.header-right-button--play {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-pause.svg?alt=media&token=7cda1222-f186-42be-b100-7d9ae072d99a);
}

.header-right-button--no-play {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-play.svg?alt=media&token=941dd5dc-6241-424d-8f31-a68daa68ebd2);
}

.header-right-button-closed {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-quitsvg.svg?alt=media&token=b40cb374-cb29-4481-a97f-33319db83898);
}