
.modalIntroduction {
    display: flex;
    margin: 5%;
}

.modalIntroduction--right {
    flex-direction: row-reverse;
}


.modalIntroduction-bubble {
    position: absolute;
}

.modalIntroduction-description {
    position: absolute;
}

.modalIntroduction-description-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.modalIntroduction-description--left {
    padding: 10vh 4vh;
    width: 35%;
 }

 .modalIntroduction-description--right {
    padding: 3vh;
    width: 35%;
 }

.modalIntroduction-description p {
    color: white;
}

.modalIntroduction-avatar.right {
    width: 40vw;
    flex: 1 1;
    position: absolute;
    bottom: 0vh;
    left: 0;
}

.modalIntroduction-avatar.left {
    width: 40vw;
    flex: 1 1;
    position: absolute;
    bottom: 0vh;
    right: 0;

}

@media screen and (max-width: 768px) {
    .modalIntroduction-description-content button {
        height: 40px;
        width: 73px;
    }
}


@media screen and (max-width: 950px) {
    .modalIntroduction-bubble {
        width: 60%;
        top: 30%;
    }

}
