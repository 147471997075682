.clockContainer {
    display: flex;
    align-items: center;
}

.clock {
    border-radius: 50%;
    background: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fclock%2Fhorloge.svg?alt=media&token=89bdfbd8-f57d-4584-8ac0-939859e95291') no-repeat center;
    background-size: 88%;
    height: 12em;
    padding-bottom: 31%;
    position: relative;
    width: 20em;
  }

.minutes-container, .hours-container, .seconds-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .hours {
    background: #99625c;
    height: 15%;
    left: 48.75%;
    position: absolute;
    top: 35%;
    width: 2.5%;
  }

  .minutes {
    background: #99625c;
    height: 30%;
    left: 49%;
    position: absolute;
    top: 20%;
    width: 2%;
  }

  .seconds {
    background: #99625c;
    height: 45%;
    left: 49.5%;
    position: absolute;
    top: 15%;
    width: 1%;
    z-index: 8;
  }

  @keyframes rotate {
    100% {
      transform: rotateZ(360deg);
    }
  }

  .hours-container {
    animation: rotate 43200s linear;
  }


  .minutes-container {
    animation: rotate 3600s;
  }
  .seconds-container {
    animation: rotate 60s infinite steps(60);
  }

