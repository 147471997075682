.codeMachine {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.codeMachine-digit {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2FcodeMachine%2Fcode-machine.svg?alt=media&token=c91e8177-1d2e-48e8-a6e5-975519a11946);
    background-repeat: no-repeat;
    background-size: contain;
    height: 58vh;
    margin: 5vh;
}

.codeMachine-digit p {
    margin-top: 23.5%;
    height: 10px;
    font-size: xx-large;
    color: white;
}

.codeMachine-digit-number {
    display: flex;
    flex-wrap: wrap;
    width: 230px;
    margin: 10vh 12vh;
}

.codeMachine-digit-number button {
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}