.questions {
    color: #000000;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ftv-grande.svg?alt=media&token=c0ede63c-ffca-42c0-a079-7d5e54df1369);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 48/13;
    min-width: 47vw;
    height: auto;
    margin: 5vh;
    padding: 9vh;
}

.questions-question h3 {
    min-width: 780px;
}

.questions-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.questions-responses {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.questions-action {
    background-color: transparent;
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.questions-check {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid #FF772C;
    background-color: #FCBE00;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 10px;
}

.questions-not-check {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid #000000;
    margin-right: 10px;

}