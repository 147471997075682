.home {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fbkg-jeu.svg?alt=media&token=b59f30a7-4d03-4548-9dda-1317d8879b0d);
    background-size: cover;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home-title {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 5vh;
}

.home-title h1, .home-title h4 {
    margin: 0;
}

.home img {
    margin: 5vh;
}