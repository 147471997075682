.puzzle {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fpuzzle%2Fpuzzle-bkg.svg?alt=media&token=46c70335-7132-41cb-b420-1526122cf987);
    background-repeat: no-repeat;
    background-size: contain;
    height: 46vh;
    display: flex;
    padding: 15px;
    align-items: center;
    margin: 5vh 7vw;
}

.puzzle-content-button {
    text-decoration: none;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
}

.puzzle-element {
    margin-right: 14vh;
}