.tiroir {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftiroir%2Ftiroir-fond.svg?alt=media&token=e3aeab0b-ba55-408f-b61d-8aecbf19b7a4);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 4vh;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.tiroir_contents {
    display: flex;
}

.tiroir_button {
    background-color: transparent;
    border: none;
    width: 22vh;
}

.tiroir_arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.tiroir_arrow.down img {
    transform: rotate(180deg);
}

.tiroir_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}