.mirror {
    display: flex;
    align-items: center;
}
.mirror_content {
    background-repeat: no-repeat;
    background-size: cover;
    height: 48vh;
    display: flex;
    flex-direction: column;
    width: 27vw;
    padding: 10vh;
}

.mirror_content p {
    margin: 0;
    padding: 0 2vw;
    color: black;
}