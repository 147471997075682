.score {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fheader%2Fbtn-score.svg?alt=media&token=b85777c8-f95b-4924-bbeb-987fb6ea0b2d);
    height: 70px;
    width: 288px;
    background-repeat: no-repeat;
    display: flex;
}

.score-rockets {
    margin-left: 72px;
}

.score img {
    width: 40px;
    margin: 19px 2px;
}