.introduction {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fbkg-dialogue.svg?alt=media&token=def65dc0-73a5-40ae-b5fd-821d66cd5943);
    background-size: cover;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
}

.dialogue{
    font-size: 1.3vw;
}