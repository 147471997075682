.button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #f05a24;
    font-weight: bold;
    text-transform: uppercase;
    background-color: transparent;
    text-decoration: none;
    border: none;
    z-index: 1;
    cursor: pointer;
    height: 7vh;
    width: 15vh;
}

.button--primary {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-start.svg?alt=media&token=d331a14d-1577-4505-bf9d-eb34b6ec7265);
    background-repeat: no-repeat;
    background-size: contain;
}

.button--primary:hover {
    background-repeat: no-repeat;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-start-hover.svg?alt=media&token=344fbae4-b953-44e8-930a-bab9a55b491e);
}

.button--second {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-court.svg?alt=media&token=b6817ca7-f4e2-43f3-bbdb-ec8d837d9f06);
    background-repeat: no-repeat;
}

.button--second:hover {
    background-repeat: no-repeat;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbuttons%2Fbtn-court-hover.svg?alt=media&token=7ea4794a-1568-48d0-9c49-01f5b824c221);
}

.button--third {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fclock%2Fhorloge-btn.svg?alt=media&token=25fe0c76-dc2c-42f1-a82d-75410363c7b2);
    color: white;
    width: 20vh;
    background-repeat: no-repeat;
}