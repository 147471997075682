.cigaret {
    display: flex;
    align-items: center;
    margin: 3vh;
}
.cigaret-content {
    display: flex;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Ftv-petite.svg?alt=media&token=e8abf904-fa3c-4dbb-bf29-588aff8d9120);
    background-size: cover;
    width: 107vh;
    height: 61vh;
    justify-content: center;
}

.cigaret-left, .cigaret-right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.cigaret-element {
    background-repeat: no-repeat;
    width: 25vh;
    height: 15%;
    background-size: contain;
    display: flex;
}

.cigaret-center {
    height: 90%;
    margin-top: 4%;
}

.nicotine {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Fnicotine.svg?alt=media&token=530329ca-b1e1-48f7-947e-f6a32df1a7a5);
}

.goudron {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Fgoudron.svg?alt=media&token=1e2aed2f-ace0-431c-9621-2b2dc6fe4d70);
}

.mercure {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Fmercure.svg?alt=media&token=db4c1037-2fbf-47f2-b9f1-68d05f74b422);
}

.arsenic {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Farsenic.svg?alt=media&token=ae1ae326-5226-481f-bb4d-da44482baa04);
}

.ammoniac {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Fammoniac.svg?alt=media&token=a2408067-7ae4-4c88-a02d-554ae4f42089);
}

.cigaret-element-button {
    background-color: transparent;
    border: none;
    text-decoration: none;
    width: 6vh;
    background-repeat: no-repeat;
}

.cigaret-element-button.plant {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Fplant.svg?alt=media&token=ea40e65d-af2c-4273-9b8d-362f4e4e31f3);
}
.cigaret-element-button.plant:hover, .cigaret-element-button.plant.valid {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Fplant-valid.svg?alt=media&token=cfc97e85-b0a6-4b16-be0d-d81c45a4dff8);
}

.cigaret-element-button.fire {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Ffire.svg?alt=media&token=441d5967-4dac-4036-afce-69209da4ac57);
}
.cigaret-element-button.fire:hover, .cigaret-element-button.fire.valid {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Ffire-valid.svg?alt=media&token=c70ed661-4310-4270-8366-d716a9c61a95);
}

.cigaret-element-button.fabric {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Ffabric.svg?alt=media&token=12bff71e-3fd4-419c-a92f-f83147a5a65a);
}

.cigaret-element-button.fabric:hover, .cigaret-element-button.fabric.valid {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcigaret%2Ffabric-valid.svg?alt=media&token=9aaced4c-618b-497c-ba68-3a87432c1653);
}

.cigaret-element-button {
    margin-top: -25px;
}

.cigaret-left .cigaret-element {
    justify-content: flex-start;
}

.cigaret-right .cigaret-element {
    justify-content: flex-end;
}

.nicotine .plant, .goudron .plant {
    margin-left: 5%;
}

.mercure .fabric {
    margin-right: 5%;
}
