.tabac {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10vh 2vh;
}
.tabac_container {
    display: flex;
}

.tabac_container-person {
    margin: 2vh;
}

.tabac_container-person-buttons {
    display: flex;
    align-items: center;
}

.tabac_container-person-buttons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

.tabac_container-person-buttons img {
    background-repeat: no-repeat;
}

.tabac_container-person-button {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftabac%2Factivite-btn.svg?alt=media&token=933090b4-1d9f-4864-8d11-e27e0735bb4c");
    background-repeat: no-repeat;
    background-size: cover;
    height: 7vh;
}

.tabac_container-person-button:hover {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Ftabac%2Factivite-btn-hover.svg?alt=media&token=f9af59a7-6d3e-44d1-898f-8d0fbacc9d2b");
    background-repeat: no-repeat;
    background-size: cover;
    height: 7vh;
}

.tabac_container-person-button-negatif {
    padding: 12px;
    height: 6px;
}

.tabac_container-person-button-positif {
    padding: 10px;
    height: 32px;
}

.tabac_container-person-number {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabac_container-person-number img {
    height: 20vh;
    margin: 0 2vh;
}

.tabac_container-person-number h3 {
    position: absolute;
    color: #FF5200;
    font-size: 50px;
}