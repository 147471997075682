.pickUp {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pickUp-capsule {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fscreen-capsule.svg?alt=media&token=e0ed3a97-2c0a-4632-9dfb-d3d0faa71baa);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 16/13;
    height: auto;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pickUp-card {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fscreen-carte.svg?alt=media&token=8316a372-3a56-468a-9d27-508a3e4fbee8);
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: 16/13;
    height: auto;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pickUp-cards {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbackground%2Fscreen-cartes.svg?alt=media&token=06e51b0a-81ce-4d42-9834-0db12f1087c6);
    background-repeat: no-repeat;
    background-size: contain;
    height: 52vh;
    width: 45%;
    display: flex;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


.pickUp img {
    max-height: 35vh;

}

.pickUp-cards img {
    max-width: 34%;
    max-height: 57%;
}

@media screen and (max-width: 768px) {
    .pickUp-capsule {
        height: 40vh;
    }
    
    .pickUp-card {
        height: 40vh;
    }
    
    .pickUp-cards {
        height: 40vh;
    }
}