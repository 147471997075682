.book {
    display: flex;
    align-items: center;
}

.book_content {
    font-family: 'Dancing Script', cursive;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fbook%2Fnotes-empty.svg?alt=media&token=7625ed34-8121-493a-b7d9-8d33b8cd157a);
    background-repeat: no-repeat;
    background-size: contain;
    color: black;
    display: flex;
    margin-right: 5vh;
}

.book_content-left {
    width: 67vh;
    height: 43vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10vh 7vh;
    flex: 1 1;
    text-align: left;
}

.book_content-left p {
    width: 251px;
}

.book_content-left-text-select {
    margin: 0 5px;
}