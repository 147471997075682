.tutorialRobot {
    display: flex;
    width: 100%;
    align-items: center;
}

.tutorialRobot_robot {
    height: 130px;
}

.tutorialRobot_robot-content {
    display: flex;
    align-items: center;
    position: absolute;
    margin-left: 15vw;
}

.tutorialRobot_robot-content  p {
    max-width: 58vw;
    margin-right: 10px;
}

.tutorialRobot_bubble {
    width: 80vw;
}

.tutorialRobot button {
    height: 8vh;
    background-size: contain;
}

.tutorialRobot .tutorialRobot_robot-content {
    margin-left: 16vw;
}

.tutorialRobot .tutorialRobot_robot-content.win {
    margin-left: 22vw;
}

@media screen and (max-width: 900px) {
    .tutorialRobot_robot {
        height: 70px;
    }
}
