.tutorial {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15vh 0;
}

.tutorial-content {
    position: absolute;
    width: 70vw;
}

.tutorial-element {
    position: relative;
    max-height: 24vh;
}