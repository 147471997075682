.color {
    margin: 19vh;
}
.color_content {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fverrou-bkg.svg?alt=media&token=6f4f64c9-c2c2-499e-a6ed-aac2f61923a0);
    background-repeat: no-repeat;
    background-size: contain;
    height: 22vh;
}

.color_content-button {
    border: none;
    background-color: transparent;
    text-decoration: none;
}

.color_content-button input {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fverrou-rouge.svg?alt=media&token=84aeffd5-4b8e-466f-b3fa-d2319f670e71);
    background-repeat: no-repeat;
    background-size: cover;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    border: none;
    background-color: transparent;
    text-align: center;
    outline: none !important;
}

.color_content-button p  {
    margin: 0;
    padding: 0;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fcolor%2Fverrou-rouge.svg?alt=media&token=84aeffd5-4b8e-466f-b3fa-d2319f670e71);
    background-repeat: no-repeat;
    background-size: cover;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 86px;
    border: none;
    background-color: transparent;
    text-align: center;
    outline: none !important;
}

.color_content-image {
    height: 20px;
    width: 20px;
    margin-top: 5px;
}