.android {
    display: flex;
    align-items: center;
    margin: 5vh;
    display: flex;
}
.android_content {
    height: 56vh;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fsamsung%2Ftel-empty.svg?alt=media&token=f286a96a-56bb-432d-b9f7-f158462910c5);
    background-repeat: no-repeat;
    color: black;
    display: flex;
    width: 20vw;
}

.android_content-buttons {
    justify-content: space-around;
    display: flex;
    margin: 21vh 2vw;
    width: 25vh;
}

.android_content-button.not-checked{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fsamsung%2Ftel-btn-cliquer.svg?alt=media&token=ab7dc822-2d9e-4483-a9a0-332de0ce2ba5');
    height: 15px;
    width: 15px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    display: flex;
    margin: 27px 0;
    background-repeat: no-repeat;
}

.android_content-button.checked {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fgames%2Fsamsung%2Ftel-btn-non%20cliquer.svg?alt=media&token=4d2e2151-666f-48a6-be48-e63f05ad727f');
    height: 15px;
    width: 15px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    margin: 27px 0;
    background-repeat: no-repeat;
    border: 3px solid #ff9f18;
}
