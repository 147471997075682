
.constellation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.constellation_content button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: absolute;
    color: white;
}

.constellation_content img {
    width: 90vh;
}

.constellation input {
    background-color: white;
    color: black;
    border: 1px solid #47155f;
    border-radius: 10px;
    height: 50px;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.constellation_buttons {
    display: flex;
    margin-bottom: 10px;
}