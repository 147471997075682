.Dictaphone button {
    border: none;
    text-decoration: none;
    background-color: transparent;
    margin: 8vh;
}
.Dictaphone-open {
    margin: 5vh;
    display: flex;
}

.Dictaphone-open-text {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Unlock%2FTABAC%2Fassets%2Fbubble%2Fbulle-robot-intro.svg?alt=media&token=a2c89ab7-7109-4c72-a436-200847c7720a);
    background-repeat: no-repeat;
    background-size: contain;
    height: 55vh;
}

.Dictaphone-open-text p {
    margin: 5vh 5vh 0 15vh;
    width: 350px;
}